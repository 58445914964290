/* Home.css */

.background-containers {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: black;
    text-align: center;
    overflow: hidden;
}

.background-container {
    position: relative;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    overflow: hidden;
}

.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* Apply the brightness filter to the background image only */
    transition: opacity 3s ease-in-out;
}


/* Add a pseudo-element to create the overlay effect */

.background-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Adjust the darkness and opacity here */
    z-index: -1;
    opacity: 0;
    transition: opacity 3s ease-in-out;
    filter: brightness(70%) saturate(70%);
}


/* Fade in the overlay on hover */

.background-container:hover::before {
    opacity: 1;
}

.text-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    /* Ensures the container takes up the full height of the background-image */
    text-align: center;
}

.visaHeading {
    font-weight: 700;
    font-size: 2em;
    padding: 0.8em;
    text-align: left;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.visaHeading1 {
    font-weight: 700;
    font-size: 2em;
    padding: 0.8em;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.catchPhrase {
    font-size: 3.5em;
    font-weight: 700;
    font-family: 'Times New Roman', Times, serif;
    padding-top: 2em;
    color: white;
}

.subCatch {
    font-weight: 600;
    color: #fff;
    width: 80%;
    margin: 0 auto;
}

.search-container {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    margin-top: 20px;
}

.search-form {
    display: flex;
    flex-direction: column;
}

.form-group {
    margin-bottom: 10px;
}

.form-control {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
    font-weight: bold;
}

.travel-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #082543;
}

.travel-info-item {
    flex: 1;
    padding: 20px;
    margin: 10px;
    text-align: center;
    max-width: 65%;
}

.travel-icon-item {
    flex: 1;
    text-align: center;
}

.travel-info-item h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    margin-top: 1em;
    color: white;
    font-weight: 700;
}

.travel-info-item p {
    font-size: 1rem;
    color: white;
}

.upcoming-trips-carasoul {
    display: flex;
    justify-content: center;
    padding: 0em 0em 2.5em;
}

.home-page-title {
    font-size: 3em;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 800;
    padding: 1em 0em 0.6em;
    display: flex;
    justify-content: center;
}

.home-page-title1 {
    font-size: 2.5em;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 800;
    padding: 2em 0em 0.6em;
    display: flex;
    justify-content: center;
    text-align: center;
}

@media (max-width: 900px) {
    .home-page-title1 {
        font-size: 2em;
        width: 75%;
        margin: auto;
    }
}

.about-us {
    max-width: 70%;
    margin: 0 auto;
    text-align: center;
}

.our-story,
.what-sets-us-apart,
.our-services,
.start-your-journey {
    margin-bottom: 40px;
}

h2 {
    color: #333;
}

p {
    color: #666;
}

.point {
    margin-bottom: 20px;
}

.ourStoryPic {
    max-width: 100%;
    height: auto;
    max-height: 10%;
    margin-top: 10px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.map {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 90%;
    margin: 0 auto;
    padding: 0 2em 3em;
}

.map1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin: 0;
    padding: 6.2em 0 0;
}

.FAQImage {
    display: 'block';
    margin: 'auto'
}

.FAQQuestionDiv {
    margin: 4em 5em;
    box-shadow: 0px 20px 10em rgba(0, 0, 0, 0.1);
    padding: 2em 4em;
    border-radius: 20px
}

.our-story-main-div {
    margin: 8em 5em 0 7em
}

.our-story-container {
    display: flex;
    margin: 4.5em 0;
    justify-content: space-between
}

.our-story-image-left {
    width: '50%'
}

.our-story-head-image {
    height: 28em;
    width: auto
}

.our-story-container1 {
    width: 50%;
    margin-left: 3em;
}

.what-provide-icon {
    color: rgb(51, 201, 73);
    font-size: 3.2em;
    padding: 0.5em;
    background-color: #15579f;
    border-radius: 50%;
    margin: 0.3em auto;
}

.what-provide-text {
    font-weight: 700;
    font-size: 1.1em;
    color: #333;
    text-align: center;
    margin: auto;
}

.what-provide-svg {
    height: 4.7em;
    margin: 0.5em auto;
}

.what-provide-svg-text {
    font-weight: 700;
    font-size: 1.1em;
    color: #333;
    text-align: center;
    width: 60%;
    margin: auto;
}

@media only screen and (max-width: 900px) {
    .what-provide-text {
        width: 30%;
    }
    .main-contact-div {
        padding: 3em 8em 3em;
    }
    .our-story-main-div {
        margin: 8em auto;
    }
    .ourStoryPic {
        max-height: 50%;
    }
    .our-story-image-left {
        width: 100%;
    }
    .our-story-head-image {
        height: 19em;
        display: flex;
        justify-content: center;
        margin: 3em auto;
    }
    .our-story-container {
        display: inline;
        justify-content: center;
    }
    .our-story-container1 {
        width: 80%;
        margin: 4em auto;
        justify-content: center;
    }
    .visaHeading1 {
        padding-top: 3em;
    }
    .FAQImage {
        width: 80%;
        height: auto;
    }
    .FAQQuestionDiv {
        margin: 4em 1em;
        padding: 2em;
    }
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin-bottom: 10px;
    color: #555;
}

.iconCard {
    background-color: #0a3460;
    color: greenyellow;
    padding: 17px 22px;
    clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
    border-radius: 50%;
}

.text-center {
    padding-top: 1.3em;
}

.cardCustom {
    margin: 0 1.5em 0;
}

.contact-us-list {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
}

.contact-us-list:hover {
    color: black;
    font-weight: 600;
}

.contact-list {
    display: flex;
    justify-content: space-between;
}

.contact-details {
    width: 30%;
}

.contact-details-list-1,
.contact-details-list-3 {
    display: flex;
}

.contact-details-list-2 {
    display: flex;
    padding: 0.6em 0
}

.contact-details-list-3-emoji {
    padding-left: 0.6em
}

.main-contact-div {
    padding: 3em 15em 3em
}

.contact-list-container {
    width: 70%;
    margin: 0 auto 1em;
}

.contact-list-title {
    padding-bottom: 0.6em;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 700;
    text-align: center;
}

.contact-list-title-1 {
    padding-bottom: 0.6em;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: 700;
}

.text-of-contact {
    color: #000;
    font-weight: 500;
}

.contact-link-text {
    color: #000;
    text-decoration: none;
}

.contact-link-text:hover {
    opacity: 0.7;
    font-weight: 700;
}

@media (max-width: 900px) {
    .contact-list-container {
        width: 90%;
        margin-left: 0.7em;
    }
    .contact-list-title,
    .contact-list-title-1 {
        text-align: left;
        font-size: 1.6em;
    }
    .contact-list-title {
        margin-top: 2.5em;
    }
    .contact-us-social-media-links {
        margin-left: 1.7em;
    }
    .main-contact-div {
        padding: 3em 7em;
    }
    .background-container {
        height: auto;
        flex-direction: column;
    }
    .contact-list {
        display: inline;
        width: 100%;
        margin: auto;
        text-align: center;
    }
    .contact-details {
        width: 100%;
        padding: 1em 0;
    }
    .background-image {
        padding: 10px;
    }
    .search-container {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 10px;
        padding: 20px;
        width: 90%;
        max-width: 400px;
        margin-top: 20px;
    }
    .search-form {
        display: flex;
        flex-direction: column;
    }
    .form-group {
        margin-bottom: 10px;
    }
    .form-control {
        padding: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;
    }
    .btn-primary {
        background-color: #0a3460;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px;
        cursor: pointer;
        font-weight: bold;
    }
    .travel-info-container {
        flex-direction: column;
    }
    .travel-info-item {
        padding: 15px;
        margin: 10px 0;
    }
    .catchPhrase {
        font-size: 2.3em;
    }
    .subCatch {
        font-size: 1em;
    }
}

.background-image-cover {
    object-fit: cover;
    width: 100%;
    height: 37em;
}

.center-text-home {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    padding: 10%;
    width: 100%;
    text-align: center;
}

.carousel .carousel-control-prev,
.carousel .carousel-control-next {
    display: none !important;
    /* Add !important to override Bootstrap styles */
}

.carousel .carousel-indicators {
    display: none !important;
    /* Add !important to override Bootstrap styles */
}


/* HomePage.css */

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.popup {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}