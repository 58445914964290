.custom-navbar {
    background-color: #ffffff;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    /* Center-align items horizontally */
    align-items: center;
    /* Center-align items vertically */
}

.logo {
    width: 50px;
    height: auto;
    margin-right: 10px;
}

.link-logo {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

@media (max-width: 900px) {}

.navbar-nav .nav-link {
    margin-right: 20px;
    /* Adjust the value to control the spacing */
}

.custom-navbar .nav-button {
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 5px;
}

.custom-navbar .login-button {
    margin-right: 10px;
}

.custom-navbar .cart-button {
    background-color: #28a745;
    color: white;
}

.custom-navbar .nav-link {
    font-size: 1em;
    color: #333;
    transition: color 0.3s ease;
    font-weight: 600;
}

.custom-navbar .nav-link:hover {
    color: #0864c7;
    /* Adjust the hover color */
    text-decoration: none;
    /* Remove underline on hover */
}

.ml-auto {
    padding: 0em 2em;
}