.destination-head {
    margin: 3.5em 2em 1.7em;
    text-align: center;
    font-size: 2.4em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.destination-head1 {
    margin: 3.5em 2em 0.8em;
    text-align: center;
    font-size: 2.4em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

@media (max-width: 900px) {
    .destination-head {
        font-size: 1.4em;
        margin-top: 6em;
    }
    .destination-head1 {
        font-size: 1.5em;
        margin: 5em 2em 0em;
    }
}

.input-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.destination-input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 2em;
}

.items {
    display: flex;
    flex-direction: column;
    justify-content: first baseline;
    align-items: first baseline;
    padding-left: 16%;
}

.form-control {
    font-size: '1.4em';
    border-radius: '0.5em';
}

.places-list {
    margin: 0;
    font-weight: 600;
    font-size: 1.25em;
    transition: color 0.3s;
    background-color: transparent;
    border: 0;
}

.places-list:hover {
    color: #1489b1;
    font-weight: 700;
}

.button-text {
    color: black;
}

.button-text:hover {
    color: #1489b1;
}

.date-div {
    display: grid;
    justify-content: center;
}

.couple-select {
    height: 110px;
    width: auto;
    border-radius: 60px;
}

.box {
    border: 2px solid #000;
    border-radius: 10px;
    background-color: white;
}

.trip-type-select {
    padding: 1.5em;
}

.trip-type-select:hover {
    opacity: 0.8;
}

@media only screen and (min-width: 768px) {
    .form-control {
        width: '100%';
    }
    .container {
        display: flex;
    }
}

.container {
    display: flex;
    justify-content: center;
}

.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.col-md-3 {
    flex: 0 0 calc(33.333% - 20px);
    margin-bottom: 20px;
}

.box {
    width: 100%;
}

@media (max-width: 768px) {
    .col-md-3 {
        flex: 0 0 calc(50% - 20px);
    }
}

@media (max-width: 576px) {
    .col-md-3 {
        flex: 0 0 calc(60% - 20px);
    }
}