.visa-heading {
    text-align: center;
    padding: 2em 0 3em;
}

.visa-title {
    font-size: 2em;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: rgba(0, 0, 0, 0.915);
}

.visa-subtitle {
    font-size: 1.15em;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: rgba(0, 0, 0, 0.750);
}

.visa-first {
    text-align: center;
    padding: 2em;
}

.visa-first-heading {
    font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-size: 2em;
    font-weight: 600;
}

.step-horizontal {
    display: inline-block;
    width: calc(20% - 1.2em);
    margin: 0 0.6em;
    vertical-align: top;
    background-color: #f4f4f4;
    padding: 1.8em;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.step-horizontal h4 {
    font-size: 18px;
    margin-bottom: 10px;
}

.step-horizontal p {
    font-size: 14px;
    line-height: 1.4;
}

.flag-icon {
    width: 20px;
    height: auto;
    margin-right: 5px;
}

.locationVisaPin {
    margin: 0 1em;
    color: gray
}

.destinationVisaPick {
    font-size: '1.2em';
    margin: 'auto'
}

@media (max-width: 900px) {
    .destinationVisaPick {
        font-size: '0.4em';
    }
}

.destinationVisaPick.focused {
    font-size: 10px;
}

.visaEntrance {
    background-color: white;
    width: 60%;
    text-align: left;
    font-size: 1.3em;
    padding: 0.4em 0;
    border-radius: 16px;
    border: 1px solid gray;
    color: gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.visaEntrance:hover {
    border: 2px solid gray;
    font-weight: 600;
    opacity: 0.7;
}

.locationSearchPin {
    display: inline-block;
    margin-left: auto;
    margin-right: 2%;
    background-color: rgb(35, 35, 142);
    padding: 13px;
    border-radius: 17px;
    color: white;
}


/* Container for the components, making them flex children */

.row-container {
    display: flex;
    gap: 20px;
    /* Adjust the gap as needed */
}


/* General styling for select boxes */

.select-box {
    flex: 1;
}


/* Styling for SelectDestination component */

#destination {
    width: 75%;
    border: 0;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    background-color: transparent;
}

#destinationMobile {
    width: 100%;
    border: 0;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    background-color: transparent;
}


/* Styling for SelectPassengerCount component */

#passengerCount {
    width: 74%;
    border: 0;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    background-color: transparent;
}

#passengerCountMobile {
    width: 100%;
    border: 0;
    border-radius: 5px;
    font-size: 16px;
    outline: none;
    background-color: transparent;
}

label {
    margin-bottom: 8px;
}

.react-datepicker {
    --primary-color: #16086b;
}

.react-datepicker__day--selected {
    color: black;
    font-weight: 900;
    background-color: transparent;
}

.react-datepicker__header {
    background-color: var(--primary-color);
    padding: 0.5em 2em;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: white;
}

.react-datepicker__day-name {
    color: white;
}

.react-datepicker__day {
    color: var(--primary-color);
    line-height: 1.7;
    width: 33px;
}

.react-datepicker__day--disabled {
    color: grey;
    cursor: not-allowed;
    opacity: 0.1;
}

.react-datepicker__day--keyboard-selected {
    background-color: transparent;
    color: var(--primary-color);
}

.react-datepicker__day:hover {
    background-color: transparent;
}

.datePicker1 {
    border: 0px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    padding: 8px;
    background-color: transparent
}

.datePicker2 {
    border: 0px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    padding: 8px;
    background-color: transparent
}

.date-icon-1 {
    margin-right: 8px
}

.date-icon-2 {
    margin-right: 8px
}

.date-div-2 {
    display: flex;
    flex-direction: column
}

@media only screen and (max-width: 920px) {
    .visaEntrance {
        width: 80%;
    }
    .Navbar {
        flex-direction: column;
        justify-content: space-between;
    }
    .Button {
        width: 100%;
        margin-top: 2em;
    }
    #passengerCount {
        width: 100%;
        margin-right: 4em;
        color: black;
    }
    #destination {
        width: 100%;
        color: black;
        margin-right: 2em;
    }
    .date-icon-1 {
        margin-left: 0.8em;
    }
    .date-icon-2 {
        margin-left: 2em;
    }
    .visaEntrance {
        font-size: 1em;
    }
}